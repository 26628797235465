
import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';
import { Form } from 'vee-validate';

import toasts from '@/utils/toasts';

import { useReCaptcha } from 'vue-recaptcha-v3';

import { User } from '@/models/UserModel';
import { Account } from '@/schemas/common.schemas';
import { Getters, Actions } from '@/store/enums/AuthEnums';
import FormField from '@/components/forms/FormField.vue';

const initialValues = {} as unknown as User;

export default defineComponent({
  name: 'sign-up-form',
  emits: ['userCreated'],
  props: {
    referral: {
      type: String,
      default: '',
    },
  },
  components: {
    FormField,
    Form,
  },
  data: () => ({
    recaptcha_token: null,
    recaptcha_error: false,
    form: {} as typeof Form,
    formData: initialValues,
    loading: false,
    submitButton: {} as HTMLElement,
  }),
  mounted() {
    // setup the form reference
    this.form = this.$refs.registrationForm as typeof Form;

    this.submitButton = this.$refs.submitButton as HTMLElement;
  },
  computed: {
    ...mapGetters({
      actionErrors: Getters.GET_AUTH_USER_ACTION_ERROR,
    }),

    formSchema() {
      return Account;
    },
  },
  methods: {
    ...mapActions({
      register: Actions.REGISTER,
    }),

    resetForm() {
      // Reset the form validation and values
      this.form.resetForm();

      // Reset the form values
      this.formData = initialValues;
    },

    setFieldValue(field, value) {
      if (!this.form.setFieldValue) return;

      this.form.setFieldValue(field, value);
    },

    async submitRegistrationForm(values) {
      if (this.loading) return;
      this.loading = true;

      const token = await this.recaptcha();

      if (!token) {
        this.recaptcha_error = true;
        this.loading = false;
        return;
      }

      this.formData = values;
      this.formData.recaptcha_token = token;
      this.formData.referral = this.referral;

      this.register(this.formData)
        .then(() => {
          this.loading = false;
          this.resetForm();
          toasts.success(
            'Account successfully created! Please check email to verify account.',
            () => {
              this.$router.replace({ name: 'sign-in' });
            }
          );
        })
        .catch(() => {
          this.loading = false;
          if (this.actionErrors) {
            const { errors, message } = this.actionErrors;
            toasts.error(errors, message);
          }
        });
    },
    validateFormField(field) {
      if (!this.form.validateField) return;

      this.form.validateField(field);
    },
  },
  setup() {
    const recaptchaInstance = useReCaptcha();

    const recaptcha = async () => {
      // (optional) Wait until recaptcha has been loaded.
      await recaptchaInstance?.recaptchaLoaded();

      // Execute reCAPTCHA with action "login".
      const token = await recaptchaInstance?.executeRecaptcha('register');

      // Do stuff with the received token.
      return token;
    };

    return {
      recaptcha,
    };
  },
});
